import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditLog } from 'app/model/AuditLog';
import { BranchUserMappedDetails } from 'app/model/branch-user-mapped-details';
import { LocationMaster } from 'app/model/LocationMaster';

import { UserMaster } from 'app/model/UserMaster';
import { UserMappingRequest } from 'app/model/UserMappingRequest';
import { BeginningCashRequest } from 'app/model/BeginningCashRequest';
import { UserValidation } from 'app/model/UserValidation';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  mainURL : string = environment.mainUrl;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http:HttpClient) { }

  GetBeginningCashListByBranch(branchId) : Observable<BeginningCashRequest[]>{

    const baseURL: string = this.mainURL + "api/admin/GetBeginningCashListByBranch/" + branchId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<BeginningCashRequest[]>(baseURL,  {
        headers: headers
    })
  }

  getBranchUserMappingDetails(branchId) : Observable<BranchUserMappedDetails[]>{

    const baseURL: string = this.mainURL + "api/admin/GetBranchUserMappingDetails/" + branchId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<BranchUserMappedDetails[]>(baseURL,  {
        headers: headers
    })
  }

  UpsertBranchUserMapping(userMappingRequest) : Observable<UserMappingRequest>{
    const baseURL: string = this.mainURL + "api/admin/UpsertBranchUserMapping";
    const body=userMappingRequest;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<UserMappingRequest>(baseURL, body, {
      headers: headers
    })
  }

  getMappedUserValidation(userValidation) : Observable<UserValidation[]>{
    const baseURL: string = this.mainURL + "api/admin/GetMappedUserValidation";
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body=userValidation;
    
    return this.http.post<UserValidation[]>(baseURL, body, {
        headers: headers
    })
  }

  deleteBranchUserMappingDetailsById(Id) : Observable<BranchUserMappedDetails>{
    const baseURL: string = this.mainURL + "api/admin/DeleteBranchUserMappingDetailsById/" +Id;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   
    return this.http.post<BranchUserMappedDetails>(baseURL, {
        headers: headers
    })
  }

  DeleteBeginningCashDetailById(Id) : Observable<BeginningCashRequest>{
    const baseURL: string = this.mainURL + "api/admin/DeleteBeginningCashDetailById/" +Id;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   
    return this.http.post<BeginningCashRequest>(baseURL, {
        headers: headers
    })
  }  

  getBranchUserMappingDetailById(Id) : Observable<BranchUserMappedDetails>{

    const baseURL: string = this.mainURL + "api/admin/GetBranchUserMappingDetailById/" +Id;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });    
    return this.http.post<BranchUserMappedDetails>(baseURL,{
        headers: headers
    })
  }

  GetBranchListByUserId(userId) : Observable<LocationMaster[]>{

    const baseURL: string = this.mainURL + "api/admin/GetBranchListByUserId/" +userId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });    
    return this.http.post<LocationMaster[]>(baseURL,{
        headers: headers
    })
  }


  getBranchList() : Observable<LocationMaster[]>{
    const baseURL: string = this.mainURL + "api/admin/GetBranchList";
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<LocationMaster[]>(baseURL,  {
        headers: headers
    })
  }

 

  getUserList() : Observable<UserMaster[]>{
    const baseURL: string = this.mainURL + "api/admin/GetUserList";
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<UserMaster[]>(baseURL,  {
        headers: headers
    })
  }


  beginningCashUpsert(beginningCashRequest) : Observable<BeginningCashRequest>{
    const baseURL: string = this.mainURL + "api/admin/BeginningCashUpsert";
    const body=beginningCashRequest;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<BeginningCashRequest>(baseURL, body, {
      headers: headers
    })
  }

  getBeginningCashByDateBranch(beginningCashRequest) : Observable<BeginningCashRequest>{

    const baseURL: string = this.mainURL + "api/admin/GetBeginningCashByDateBranch";
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body=beginningCashRequest;
    
    return this.http.post<BeginningCashRequest>(baseURL, body, {
        headers: headers
    })
  }



}
