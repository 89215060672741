// export const environment = {
//   production: true,
//   name: 'dev',
//   clientId: 'fe03b1ef-5d8d-4985-a6ee-aec8c69c825b',
//   authority: 'https://login.microsoftonline.com/8db90014-ca66-493d-b1b1-aa9ce0ba9aed/',
//   redirectUrl: 'http://localhost:4200/#/home',
//   postLogoutRedirectUri: 'http://localhost:4200/exit',
//   apiEndpoint: 'http://localhost:50869/api/',
//   scopeUri: "api://fe03b1ef-5d8d-4985-a6ee-aec8c69c825b/fbm-api-access",
//   mainUrl : 'https://fbmprodwebapp01.azurewebsites.net/'//PROD
// };


export const environment = {
  production: true,
  name: 'prod',
  clientId: '6b3de5ea-c668-4205-9b87-ee5895bbed7c', //this old one for testing please ignore  
  authority: 'https://login.microsoftonline.com/8db90014-ca66-493d-b1b1-aa9ce0ba9aed/', 
  tenantId: '8db90014-ca66-493d-b1b1-aa9ce0ba9aed', 
  redirectUrl: 'https://creditcardinspection.fbmsales.com/login', //Prod  
  postLogoutRedirectUri: 'https://creditcardinspection.fbmsales.com/logout', //Prod
  scopeUri : "api://4d6132be-1758-4f24-998d-4746b962f99f/creditcard-api-access",
  uiClienId: '6b3de5ea-c668-4205-9b87-ee5895bbed7c', // prod web app
  mainUrl : 'https://creditcarddeviceinspectionprodwebapi.azurewebsites.net/',
  isNativeLogin:false,
  apiKey : '9c712f599d59483d88e51a4f9a77effff30cfe3b129943298291d844e92596c7' 
};
