export class Userdata {
    UserId : number;
    RoleId : number;
    BranchId : string;
    FirstName : string;
    LastName : string;
    UserName : string;
    EmailId : string;
    IsProd : boolean;
    IsAdmin : number;
    userName : string;
}
