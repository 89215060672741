import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserDetails } from 'app/model/UserDetails';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { UserValidation } from 'app/model/UserValidation';

@Injectable({
  providedIn: 'root'
})
export class UserMaintenanceService {
  mainURL : string = environment.mainUrl;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  
  constructor(private http:HttpClient) { }

  getUsersDetails() : Observable<UserDetails[]> {
    const baseURL: string = this.mainURL + "api/ADUser/GetGroupMembers";
    
    const body=null;
    
    return this.http.post<UserDetails[]>(baseURL, body, {
      headers: this.headers
    })
  }

 

 


  
}
