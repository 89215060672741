import { Component} from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private broadcastService: MsalBroadcastService, private authService: MsalService) { }
  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    // if (isIE) {
    //   this.authService.loginRedirect({
    //     extraScopesToConsent: ["user.read", "openid", "profile"]
    //   });
    // } else {
    //   this.authService.loginPopup({
    //     extraScopesToConsent: ["user.read", "openid", "profile"]
    //   });
    // }
  }
}
