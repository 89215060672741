import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService} from '../services/spinner.service';
import { ModelServiceService } from '../services/ModelService.service';
import { UserMaintenanceService } from '../services/userMaintenance.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserDetails } from 'app/model/UserDetails';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from 'app/services/admin.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-Login',
  templateUrl: './Login.component.html',
  styleUrls: ['./Login.component.css']
})
export class LoginComponent implements OnInit {
  userDetails : UserDetails[];
  users: any[];

   
  constructor(private router: Router, private http:HttpClient, private route: ActivatedRoute,
  public spinner : SpinnerService, private modelService : ModelServiceService,
  public userMaintenanceService :UserMaintenanceService,private adminService:AdminService,
  private msalService: MsalService) 
  { 
    
  }

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  bufferValue = 75;
  url: string;
  isNativeLogin = environment.isNativeLogin;
  ngOnInit() {
    //debugger;
    // this.isNativeLogin = true;
    // this.url = this.router.url;
    // this.getUserMaster();

    if(!this.isNativeLogin){
      const account = this.msalService.instance.getAllAccounts()[0];
      debugger;
      // console.log("account" ,account);
      if (account) {
        // debugger;
        if (account.idTokenClaims?.preferred_username)     
          this.modelService.userObj.email = account.idTokenClaims.preferred_username;
          this.modelService.userEmail =  account.idTokenClaims.preferred_username;
        if(account.idTokenClaims?.name) 
            this.modelService.userName = account.idTokenClaims.name;
            // this.modelService.userName = this.modelService.userObj.email.split('.')[0];
          //  console.log(this.modelService.userName);
           
          this.validateUser(account.idTokenClaims.preferred_username);         
          //this.validateUserInGroups();
        } else {
          Swal.fire({
            title: 'Login Failed',
            text: "User is not authorised to access this application, please contact your system administrator",
            icon: 'warning',
            confirmButtonColor: '#103355',
            confirmButtonText: 'OK'
          }).then((result:any) => {
            if (result.isConfirmed) {
              this.router.navigate(['/login']);
            }
          })
        }}
  
  }

  onLogin(loginForm: NgForm) {    
    this.spinner.showSpinner();
    this.http.get<any>("assets/users.json").subscribe((data)=>{
      if(data){
        this.users = data;

        if(this.users){
          debugger;
          this.users = this.users.filter(s => s.email.toLocaleLowerCase() == loginForm.value.userName.toLocaleLowerCase()
                                            && s.password== loginForm.value.password);
          if(this.users.length > 0)
          {
            debugger;
            this.modelService.userEmail = this.users[0].email;
            this.modelService.userName = this.users[0].userName;
            this.modelService.isAdmin = this.users[0].admin;
            //this.modelService.roleId = this.users[0].roleId;
                  
             if(this.modelService.userEmail){
              // let userItem= this.userDetails.filter(user => user.email.toLocaleLowerCase() == this.users[0].email.toLocaleLowerCase());
              // if(userItem.length > 0 ){
              //   this.modelService.isAdmin = userItem[0].isAdmin;
              // }
            //   let url;
            //   this.route.queryParams.filter(x=>x.redirectUrl).subscribe(x=>{
            //   url = x.redirectUrl;
            //   console.log('url',url);
            // })
            // if(url){
            //   this.router.navigateByUrl(url);
            // }else{
            //   this.router.navigateByUrl('/dashboard'); 

            // }
            this.router.navigateByUrl('/dashboard'); 

          }   
          }
          else{
            Swal.fire({
              title: 'Login Failed',
              text: "User is not authorised to access this application, please contact your system administrator",
              icon: 'warning',
              confirmButtonColor: '#103355',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
              this.router.navigate(['/login']); 
              }
            })
          }
         }
      }
    });
    this.spinner.stopSpinner();
  }

  validateUser(userEmail){
    // console.log('in validate user in group');
    debugger;
    this.spinner.showSpinner();  
    this.userMaintenanceService.getUsersDetails().subscribe(data => {
      if(data){
        this.userDetails = data;
        this.modelService.DLUserDetails = new BehaviorSubject(this.userDetails);

        var filterUserData = this.userDetails.filter(x => x.email.toUpperCase() == userEmail.toUpperCase())
        if(filterUserData.length > 0)
        {
          this.modelService.isAdmin= filterUserData[0].isAdmin;
          //this.modelService.isAdmin= false;
          //this.modelService.userName = filterUserData[0].email;
        //   let url;
        //   this.route.queryParams.filter(x=>x.redirectUrl).subscribe(x=>{
        //   url = x.redirectUrl;
        //   console.log('url',url);
        // })
        // if(url){
        //   this.router.navigateByUrl(url);
        // }else{
        //   this.router.navigateByUrl('/dashboard'); 

        // }

          this.router.navigate(['/dashboard']);
          
        }
        else{
          // Swal.fire({
          //   title: 'Login Failed',
          //   text: "User ( " + userEmail + " ) is not authorised to access this application, please contact your system administrator",
          //   icon: 'warning',
          //   confirmButtonColor: '#103355',
          //   confirmButtonText: 'OK'
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //    localStorage.clear();  
          //    sessionStorage.clear();
          //    this.msalService.logout();  
          //   }
          // })
          this.validateUserBranchMapping(userEmail);
        }
       
        this.spinner.stopSpinner();  
      }
    });
    
  }

  getUserMaster(){
    this.spinner.showSpinner();  
   
    this.userMaintenanceService.getUsersDetails().subscribe(data => {
      //debugger
      if(data){
        debugger
       
        this.userDetails = data;
       
        this.spinner.stopSpinner();  
      }
    });
  }


  validateUserBranchMapping(userEmail){
    this.adminService.GetBranchListByUserId(userEmail).subscribe(data => {
      debugger;
      if(data){
        debugger;
       // console.log('user-branch', data)
        if(data.length === 0 ){
          Swal.fire({
              title: 'Login Failed!<br>No Branch Mapping Found.',
              text: "User ( " + userEmail + " ) is not mapped to any Branch, please contact your system administrator",
              icon: 'warning',
              confirmButtonColor: '#103355',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
               localStorage.clear();  
               sessionStorage.clear();
               this.msalService.logout();  
              }
            })
        }
        else{
          this.modelService.isAdmin=false;
          this.router.navigate(['/dashboard']);
        //   let url;
        //   this.route.queryParams.filter(x=>x.redirectUrl).subscribe(x=>{
        //   url = x.redirectUrl;
        //   console.log('url',url);
        // })
        // if(url){
        //   this.router.navigateByUrl(url);
        // }else{
        //   this.router.navigateByUrl('/dashboard'); 

        // }

        }
      }
    });
  }

  onLoginClick()
  {
    this.router.navigate(['/login']);
  }
  

  onAzureADLoginClick()
  {
    this.router.navigate(['/ADLogin']);
  }


 }
