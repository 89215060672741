import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './Login/Login.component';
import { DefaultComponent } from './layouts/default/default.component';
import { MsalGuard } from '@azure/msal-angular';

import { LogoutComponent } from './logout/logout.component';
import { BrowserModule } from '@angular/platform-browser';

const routes: Routes =[

  //SSO Login
  // {
  //   path: 'ADLogin',component : LoginComponent, canActivate: [MsalGuard] 
  // },
  { path: 'login',component : LoginComponent, canActivate: [MsalGuard]   },
  { path: 'login/:userid',     component: LoginComponent , canActivate: [MsalGuard]   },
  { path: 'logout',component : LogoutComponent },
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, 
  {
    path: '',
    component: DefaultComponent,
    children: [{
      path: '',
      //loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      // loadChildren: './layouts/default/default.module#DefaultModule'
      loadChildren : ()=>import('./layouts/default/default.module').then(x=>x.DefaultModule)  
      
    },
    { path: '**', component: LoginComponent, canActivate: [MsalGuard]  },
  ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
