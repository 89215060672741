import { Injectable } from '@angular/core';
import { Userdata } from '../model/userdata';
import { BehaviorSubject } from 'rxjs';
import { UserDetails } from 'app/model/UserDetails';

@Injectable({
  providedIn: 'root'
})
export class ModelServiceService {
    private userDetails;
    private incentiveAppCalendar;
    //isIEBrowser = true;
    isIEBrowser : BehaviorSubject<boolean>;

    DLUserDetails : BehaviorSubject<UserDetails[]>;
    userObj : UserDetails;
    userName : string = null;
    userEmail : string = null; isAdmin : boolean = false;
    constructor() { 
      this.userDetails = Userdata;
      this.isIEBrowser = new BehaviorSubject(true);

      this.userObj = new UserDetails;
      this.DLUserDetails = new BehaviorSubject([]);
      this.userName = null; this.userEmail = null;
      this.isAdmin = false;
    }

    addIncentiveAppCalendar(item)
    {
      debugger;
      this.incentiveAppCalendar.payrollDate = item.payrollDate;
      this.incentiveAppCalendar.weekNumber = item.weekNumber;
      this.incentiveAppCalendar.weekId = item.weekId;
    }
    
 
    addItem(item) {
        debugger;
        this.userDetails.UserId = item.userId;
        this.userDetails.RoleId = item.roleId;
        this.userDetails.BranchId = item.branchId;
        this.userDetails.FirstName = item.firstName;
        this.userDetails.LastName = item.lastName;
        this.userDetails.EmailId = item.emailId;
        this.userDetails.IsProd = false;
        this.userDetails.IsAdmin = item.isAdmin;
    }
 
    getItems(): Userdata {
        return this.userDetails;
    }

}
