import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {PortalModule} from '@angular/cdk/portal';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule }  from '@angular/platform-browser/animations'
import { AppComponent } from './app.component';
import { environment } from 'environments/environment';


import {
  AgmCoreModule
} from '@agm/core';

import { LoginComponent } from './Login/Login.component';

import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import { SpinnerService } from './services/spinner.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { ModelServiceService} from './services/ModelService.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';


import { MsalModule, MsalInterceptor, MsalService, MsalGuard, MsalBroadcastService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { HeaderInterceptor } from 'app/interceptors/headerinterceptor';
import { LogoutComponent } from './logout/logout.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from './app.config';



const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const protectedResourceMap: any =
  [
    [environment.mainUrl, environment.scopeUri]
  ];

  
@NgModule({
  imports: [
     MatDialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    MatRippleModule,
    MatSelectModule,
    RouterModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    
    MatRadioModule,
    MatMenuModule, MatProgressBarModule, MatProgressSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    OverlayModule,
    PortalModule,
    MatSidenavModule,
    MsalModule
  //   MsalModule.forRoot({
  //     auth: {
  //       clientId: environment.uiClienId,
  //       authority: environment.authority,
  //       redirectUri: environment.redirectUrl,
  //       postLogoutRedirectUri: environment.postLogoutRedirectUri,
  //     //cacheLocation: 'localStorage',
  //       validateAuthority: true,
  //       navigateToLoginRequestUrl: false
  //     }, cache: {
  //         cacheLocation: 'localStorage',
  //         storeAuthStateInCookie: isIE, // set to true for IE 11
  //       },
  //       framework: {
  //         isAngular: true
  //       },
  // }, {
  //   //popUp: !isIE,
  //   popUp: false,
  //   consentScopes: [
  //     'user.read',
  //     'openid',
  //     'profile',
  //     environment.scopeUri
  //   ],
  //   protectedResourceMap: [
  //     //[environment.mainUrl, ['api://e45ca2ca-bfa2-48ae-bcd8-636004366f34/incentive-api-access']]
  //     ['https://graph.microsoft.com/v1.0/me', ['user.read']]
  //     //protectedResourceMap
  //   ]
  // })
  ],
  declarations: [					
    AppComponent,
    // AdminLayoutComponent,
      LoginComponent,
      LogoutComponent   
      
   ],
  providers: [ SpinnerService, ModelServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
  MsalService , MsalGuard , MsalBroadcastService],
  bootstrap: [AppComponent]
})
export class AppModule { }
